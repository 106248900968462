import React, { useCallback, useMemo } from 'react';
import { IconButton, Tooltip, Box } from '@mui/material';
import { BackHand, Preview, People, Warehouse, Logout, AirportShuttle, SavedSearch, Undo, LocalShipping } from '@mui/icons-material';
import { AppProvider, NavigationItem } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../store/slices/authSlice';
import { useAppDispatch } from '../../store';
import { ruRU } from '@mui/material/locale';

const NAVIGATION: NavigationItem[] = [
  {
    kind: 'header',
    title: 'Приемка',
  },
  {
    segment: 'inbounds',
    title: 'Входящие поставки',
    icon: <Warehouse />,
  },
  {
    kind: 'divider',
  },
  {
    segment: 'postings-all',
    title: 'Все посылки',
    icon: <LocalShipping />,
  },
  {
    kind: 'divider',
  },
  {
    segment: 'postings-to-send',
    title: 'Посылки к отправке',
    icon: <Preview />,
  },
  {
    kind: 'divider',
  },
  {
    segment: 'postings-to-return',
    title: 'Посылки к возврату',
    icon: <BackHand />,
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Настройки',
  },
  {
    segment: 'dictionary',
    title: 'Индикаторы ОГ',
    icon: <SavedSearch />,
  },
  {
    kind: 'divider',
  },
  {
    kind: 'page',
    segment: 'users',
    title: 'Пользователи',
    icon: <People />,
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Отгрузка',
  },
  {
    segment: 'returnships',
    title: 'Возвратные поставки',
    icon: <Undo />,
  },
  {
    kind: 'divider',
  },
  {
    kind: 'page',
    segment: 'outbounds',
    title: 'Исходящие поставки',
    icon: <AirportShuttle />,
  },
];

const theme = createTheme(
  {
    palette: {
      mode: 'light',
    },
  },
  ruRU
);

export const DashboardLayoutWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    dispatch(logout())
      .unwrap()
      .then(() => {
        navigate('/login');
      })
      .catch((error: unknown) => {
        console.error('Ошибка выхода:', error);
      });
  }, [dispatch, navigate]);

  const router = useMemo(() => {
    return {
      pathname: window.location.pathname,
      searchParams: new URLSearchParams(),
      navigate: (url: string | URL) => {
        const path = typeof url === 'string' ? url : url.toString();
        navigate(path);
      },
    };
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <AppProvider navigation={NAVIGATION} router={router} branding={{ title: 'Аэродар', logo: <span /> }}>
        <DashboardLayout
          defaultSidebarCollapsed={true}
          slots={{
            toolbarActions: () => (
              <>
                <Tooltip title="Выйти" arrow>
                  <IconButton color="inherit" onClick={handleLogout} aria-label="Выйти">
                    <Logout />
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
        >
          <Box
            component="main"
            sx={{
              position: 'absolute',
              top: 70, // Высота toolbar
              left: 60, // Ширина скрытого navbar
              right: 0,
              bottom: 0,
              maxWidth: 'calc(100% - 70px)', // С учетом отступа
              overflow: 'auto', // Добавляем прокрутку, если контент больше контейнера
              padding: 2,
            }}
          >
            {children}
          </Box>
        </DashboardLayout>
      </AppProvider>
    </ThemeProvider>
  );
};
