import { createSelector } from 'reselect';
import { RootState } from '../index';
import { OutboundsState } from '../slices/outboundsSlice';

const selectOutboundsState = (state: RootState): OutboundsState => state.outboundsState;

export const selectOutbounds = createSelector([selectOutboundsState], (outboundsState) => outboundsState.outbounds);
export const selectTotalRowOutbounds = createSelector([selectOutboundsState], (outboundsState) => outboundsState.totalRows);

export const selectWaitOutboundsTableFetch = createSelector([selectOutboundsState], (outboundsState) => outboundsState.waitTableFetch);
export const selectOutboundsTableFetchError = createSelector([selectOutboundsState], (outboundsState) => outboundsState.tableFetchError);

export const selectWaitOutboundMesure = createSelector([selectOutboundsState], (outboundsState) => outboundsState.waitMeasure);
export const selectOutboundMesureError = createSelector([selectOutboundsState], (outboundsState) => outboundsState.mesureError);

export const selectWaitOutboundPack = createSelector([selectOutboundsState], (outboundsState) => outboundsState.waitPack);
export const selectOutboundPackError = createSelector([selectOutboundsState], (outboundsState) => outboundsState.packError);

export const selectWaitOutboundSend = createSelector([selectOutboundsState], (outboundsState) => outboundsState.waitSend);
export const selectOutboundsSendError = createSelector([selectOutboundsState], (outboundsState) => outboundsState.sendError);

export const selectWaitOutboundSetCargoDate = createSelector([selectOutboundsState], (outboundsState) => outboundsState.waitCargoDateSet);
export const selectOutboundsCargoDateSetError = createSelector([selectOutboundsState], (outboundsState) => outboundsState.cargoDateSetError);
