import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '../components/protectedRoute';

import {
  LoginPage,
  HomePage,
  UsersPage,
  UserPage,
  OutboundsPage,
  ReturnShipsPage,
  PostingsAllPage,
  PostingsToSendPage,
  PostingsToReturnPage,
  DictionariesPage,
  DictionaryPage,
  InboundsPage,
} from '../pages';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        >
          <Route path="users" element={<UsersPage />} />
          <Route path="users/create" element={<UserPage />} />
          <Route path="users/edit/:id" element={<UserPage />} />
          <Route path="inbounds" element={<InboundsPage />} />
          <Route path="postings-all" element={<PostingsAllPage />} />
          <Route path="postings-to-send" element={<PostingsToSendPage />} />
          <Route path="postings-to-return" element={<PostingsToReturnPage />} />
          <Route path="returnships" element={<ReturnShipsPage />} />
          <Route path="dictionary" element={<DictionariesPage />} />
          <Route path="dictionary/create" element={<DictionaryPage />} />
          <Route path="dictionary/edit/:id" element={<DictionaryPage />} />
          <Route path="outbounds" element={<OutboundsPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
