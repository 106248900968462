import React from 'react';

export const RedirectCell = ({ value, url }: { value: any; url: string }) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(url, '_blank');
    }
  };

  return (
    <div onClick={handleClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
      {value}
    </div>
  );
};
